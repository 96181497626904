<template>
  <div class="step-one tutorial">
    <h2>Creating a Devops Repository</h2>
    <h4>Azure Devops</h4>
    <h4>Creating a Project</h4>
    <h4>.gitignore</h4>
    <h4>Branches</h4>
    <h4>Cloning and commiting</h4>
  </div>
</template>
